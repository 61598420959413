/* Pasting all the css here because we don't have tailwind in this project and we are not planning to add it */
#legal-footer {
  justify-content: flex-start;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 12px;
  font-family: 'EuclidCircularB', sans-serif;
  margin: 64px 0;
  padding: 0 2rem;
  row-gap: 16px;
}

#legal-footer ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 16px;
}

#legal-footer ul li {
  display: flex;
  flex-shrink: 0;
}

#legal-footer a,
#legal-footer a:visited,
#legal-footer a:active {
  color: #284849;
  text-decoration: none;
}

#legal-footer span:after,
#legal-footer ul li::after {
  content: '\2022'; /* Bullet points */
  position: relative;
  margin: 0 8px;
  display: none;
}

@media (min-width: 640px) {
  #legal-footer {
    flex-direction: row;
    font-size: 14px;
    margin: 30px auto;
    padding: 0.75rem;
  }
  #legal-footer ul {
    flex-direction: row;
    gap: none;
  }
  #legal-footer span:after,
  #legal-footer ul li:not(:last-child)::after {
    display: inline;
  }
}
