@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  [class^='ph-'],
  [id^="downshift-"] {
    box-sizing: border-box;
  }
  .ph-main-gradient {
    @apply ph-bg-gradient-to-b ph-from-pianissimo ph-via-pearl ph-to-ghost;
  }
  .ph-page-wrapper {
    @apply ph-w-full ph-min-h-screen ph-flex ph-flex-col;
  }
  .ph-tooltip-top {
    @apply ph-transform ph--translate-x-1/2 ph--translate-y-1 ph-bottom-full ph-left-1/2;
  }
  .ph-tooltip-right {
    @apply ph-transform ph-translate-x-2 ph--translate-y-1/2 ph-top-1/2 ph-left-full;
  }
  .ph-tooltip-bottom {
    @apply ph-transform ph--translate-x-1/2 ph-translate-y-1 ph-top-full ph-left-1/2;
  }
  .ph-tooltip-left {
    @apply ph-transform ph--translate-x-2 ph--translate-y-1/2 ph-top-1/2 ph-right-full;
  }
}
