.wrapper {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 70px);
  display: flex;
  background: linear-gradient(
    196.21deg,
    #f2e5e0 3.56%,
    #f6f2ee 43.43%,
    #eaecfa 101.58%
  );
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.content {
  max-width: 800px;
  text-align: center;
}
