/* purgecss start ignore */

/* Pasting all the css here because we don't have tailwind in this project and we are not planning to add it */

#legal-footer {
  justify-content: flex-start;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 12px;
  font-family: 'EuclidCircularB', sans-serif;
  margin: 64px 0;
  padding: 0 2rem;
  grid-row-gap: 16px;
  row-gap: 16px;
}

#legal-footer ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  grid-gap: 16px;
  gap: 16px;
}

#legal-footer ul li {
  display: flex;
  flex-shrink: 0;
}

#legal-footer a,
#legal-footer a:visited,
#legal-footer a:active {
  color: #284849;
  text-decoration: none;
}

#legal-footer span:after,
#legal-footer ul li::after {
  content: '\2022'; /* Bullet points */
  position: relative;
  margin: 0 8px;
  display: none;
}

@media (min-width: 640px) {
  #legal-footer {
    flex-direction: row;
    font-size: 14px;
    margin: 30px auto;
    padding: 0.75rem;
  }

  #legal-footer ul {
    flex-direction: row;
    grid-gap: none;
    gap: none;
  }

  #legal-footer span:after,
  #legal-footer ul li:not(:last-child)::after {
    display: inline;
  }
}

/* purgecss end ignore */

*, ::before, ::after {
  border-color: currentColor;
}

.form-input,.form-select {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

.form-input:focus, .form-select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}

.form-input::-webkit-input-placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input::placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}

[class^='ph-'] {
  box-sizing: border-box;
}

.ph-main-gradient {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #f2e5e0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(242, 229, 224, 0));
  --tw-gradient-stops: var(--tw-gradient-from), #f6f2ee, var(--tw-gradient-to, rgba(246, 242, 238, 0));
  --tw-gradient-to: #eaecfa;
}

.ph-page-wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
}

.ph-tooltip-top {
  bottom: 100%;
  left: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%;
  --tw-translate-y: -0.25rem;
}

.ph-tooltip-right {
  top: 50%;
  left: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 0.5rem;
  --tw-translate-y: -50%;
}

.ph-tooltip-bottom {
  top: 100%;
  left: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%;
  --tw-translate-y: 0.25rem;
}

.ph-tooltip-left {
  top: 50%;
  right: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -0.5rem;
  --tw-translate-y: -50%;
}

.ph-pointer-events-none {
  pointer-events: none;
}

.ph-visible {
  visibility: visible;
}

.ph-invisible {
  visibility: hidden;
}

.ph-fixed {
  position: fixed;
}

.ph-absolute {
  position: absolute;
}

.ph-relative {
  position: relative;
}

.ph-top-0 {
  top: 0px;
}

.ph-top-3 {
  top: 0.75rem;
}

.ph-top-4 {
  top: 1rem;
}

.ph-top-6 {
  top: 1.5rem;
}

.ph-top-10 {
  top: 2.5rem;
}

.ph-top-11 {
  top: 2.75rem;
}

.ph-right-0 {
  right: 0px;
}

.ph-right-4 {
  right: 1rem;
}

.ph-right-5 {
  right: 5px;
}

.ph-right-7 {
  right: 1.75rem;
}

.ph-right-8 {
  right: 2rem;
}

.ph-right-10 {
  right: 2.5rem;
}

.ph-right-auto {
  right: auto;
}

.ph-bottom-0 {
  bottom: 0px;
}

.ph--bottom-2 {
  bottom: -0.5rem;
}

.ph--bottom-5 {
  bottom: -5px;
}

.ph-bottom-1\/2 {
  bottom: 50%;
}

.ph-left-0 {
  left: 0px;
}

.ph-z-10 {
  z-index: 10;
}

.ph-z-20 {
  z-index: 20;
}

.ph-z-50 {
  z-index: 50;
}

.ph-m-0 {
  margin: 0px;
}

.ph-m-auto {
  margin: auto;
}

.ph-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ph-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.ph-my-md {
  margin-top: 32px;
  margin-bottom: 32px;
}

.ph-mt-1 {
  margin-top: 0.25rem;
}

.ph-mt-2 {
  margin-top: 0.5rem;
}

.ph-mt-4 {
  margin-top: 1rem;
}

.ph-mt-5 {
  margin-top: 5px;
}

.ph-mt-6 {
  margin-top: 1.5rem;
}

.ph-mt-7 {
  margin-top: 1.75rem;
}

.ph-mt-8 {
  margin-top: 2rem;
}

.ph-mt-9 {
  margin-top: 2.25rem;
}

.ph-mt-32 {
  margin-top: 8rem;
}

.ph-mt-xxs {
  margin-top: 16px;
}

.ph-mt-xs {
  margin-top: 20px;
}

.ph-mt-sm {
  margin-top: 24px;
}

.ph-mt-md {
  margin-top: 32px;
}

.ph-mt-lg {
  margin-top: 48px;
}

.ph-mt-xl {
  margin-top: 64px;
}

.ph--mt-1 {
  margin-top: -0.25rem;
}

.ph--mt-2 {
  margin-top: -0.5rem;
}

.ph--mt-1\.5 {
  margin-top: -0.375rem;
}

.ph-mr-1 {
  margin-right: 0.25rem;
}

.ph-mr-2 {
  margin-right: 0.5rem;
}

.ph-mr-4 {
  margin-right: 1rem;
}

.ph-mr-1\.5 {
  margin-right: 0.375rem;
}

.ph-mr-xs {
  margin-right: 20px;
}

.ph--mr-1 {
  margin-right: -0.25rem;
}

.ph-mb-0 {
  margin-bottom: 0px;
}

.ph-mb-1 {
  margin-bottom: 0.25rem;
}

.ph-mb-2 {
  margin-bottom: 0.5rem;
}

.ph-mb-3 {
  margin-bottom: 0.75rem;
}

.ph-mb-4 {
  margin-bottom: 1rem;
}

.ph-mb-5 {
  margin-bottom: 5px;
}

.ph-mb-6 {
  margin-bottom: 1.5rem;
}

.ph-mb-7 {
  margin-bottom: 1.75rem;
}

.ph-mb-8 {
  margin-bottom: 2rem;
}

.ph-mb-10 {
  margin-bottom: 2.5rem;
}

.ph-mb-20 {
  margin-bottom: 5rem;
}

.ph-mb-2\.5 {
  margin-bottom: 0.625rem;
}

.ph-mb-xxs {
  margin-bottom: 16px;
}

.ph-mb-xs {
  margin-bottom: 20px;
}

.ph-mb-sm {
  margin-bottom: 24px;
}

.ph-mb-md {
  margin-bottom: 32px;
}

.ph-mb-lg {
  margin-bottom: 48px;
}

.ph-ml-1 {
  margin-left: 0.25rem;
}

.ph-ml-auto {
  margin-left: auto;
}

.ph-box-border {
  box-sizing: border-box;
}

.ph-box-content {
  box-sizing: content-box;
}

.ph-block {
  display: block;
}

.ph-inline-block {
  display: inline-block;
}

.ph-flex {
  display: flex;
}

.ph-inline-flex {
  display: inline-flex;
}

.ph-hidden {
  display: none;
}

.ph-h-1 {
  height: 1px;
}

.ph-h-2 {
  height: 0.5rem;
}

.ph-h-3 {
  height: 0.75rem;
}

.ph-h-4 {
  height: 1rem;
}

.ph-h-6 {
  height: 1.5rem;
}

.ph-h-7 {
  height: 1.75rem;
}

.ph-h-16 {
  height: 4rem;
}

.ph-h-70 {
  height: 70px;
}

.ph-h-full {
  height: 100%;
}

.ph-h-screen {
  height: 100vh;
}

.ph-h-header-top {
  height: calc(100vh-75px);
}

.ph-min-h-screen {
  min-height: 100vh;
}

.ph-w-2 {
  width: 0.5rem;
}

.ph-w-3 {
  width: 0.75rem;
}

.ph-w-4 {
  width: 1rem;
}

.ph-w-6 {
  width: 1.5rem;
}

.ph-w-48 {
  width: 12rem;
}

.ph-w-56 {
  width: 14rem;
}

.ph-w-auto {
  width: auto;
}

.ph-w-1\/2 {
  width: 50%;
}

.ph-w-full {
  width: 100%;
}

.ph-w-screen {
  width: 100vw;
}

.ph-w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.ph-min-w-min {
  min-width: -webkit-min-content;
  min-width: min-content;
}

.ph-max-w-150 {
  max-width: 150px;
}

.ph-max-w-sm {
  max-width: 24rem;
}

.ph-max-w-lg {
  max-width: 32rem;
}

.ph-max-w-xl {
  max-width: 36rem;
}

.ph-max-w-full {
  max-width: 100%;
}

.ph-flex-grow {
  flex-grow: 1;
}

.ph-transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.ph-translate-y-1\/2 {
  --tw-translate-y: 50%;
}

@-webkit-keyframes ph-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ph-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ph-ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ph-ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes ph-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes ph-pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes ph-bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes ph-bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@-webkit-keyframes ph-bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,0,0) scale(0.5);
            transform: translate3d(0,0,0) scale(0.5);
  }

  20% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translate3d(0,0,0) scale(1.3);
            transform: translate3d(0,0,0) scale(1.3);
  }

  100% {
    -webkit-transform: translate3d(0,0,0) scale(1);
            transform: translate3d(0,0,0) scale(1);
  }
}

@keyframes ph-bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,0,0) scale(0.5);
            transform: translate3d(0,0,0) scale(0.5);
  }

  20% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translate3d(0,0,0) scale(1.3);
            transform: translate3d(0,0,0) scale(1.3);
  }

  100% {
    -webkit-transform: translate3d(0,0,0) scale(1);
            transform: translate3d(0,0,0) scale(1);
  }
}

@-webkit-keyframes ph-bounceOut {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0) scale(1);
            transform: translate3d(0,0,0) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0,0,0) scale(0.1);
            transform: translate3d(0,0,0) scale(0.1);
  }
}

@keyframes ph-bounceOut {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0) scale(1);
            transform: translate3d(0,0,0) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0,0,0) scale(0.1);
            transform: translate3d(0,0,0) scale(0.1);
  }
}

@-webkit-keyframes ph-rippleOut {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  20% {
    opacity: 0.15;
  }

  70% {
    opacity: 0;
  }

  99% {
    -webkit-transform: scale(15);
            transform: scale(15);
  }

  100% {
    visibility: hidden;
  }
}

@keyframes ph-rippleOut {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  20% {
    opacity: 0.15;
  }

  70% {
    opacity: 0;
  }

  99% {
    -webkit-transform: scale(15);
            transform: scale(15);
  }

  100% {
    visibility: hidden;
  }
}

@-webkit-keyframes ph-scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0) scale(0.2);
            transform: translateX(0) scale(0.2);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}

@keyframes ph-scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0) scale(0.2);
            transform: translateX(0) scale(0.2);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}

.ph-animate-ping {
  -webkit-animation: ph-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
          animation: ph-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.ph-animate-bounce-in {
  -webkit-animation: ph-bounceIn 0.7s 0.2s forwards;
          animation: ph-bounceIn 0.7s 0.2s forwards;
}

.ph-animate-bounce-out {
  -webkit-animation: ph-bounceOut 0.3s forwards;
          animation: ph-bounceOut 0.3s forwards;
}

.ph-animate-scale-in {
  -webkit-animation: ph-scaleIn 0.3s forwards;
          animation: ph-scaleIn 0.3s forwards;
}

.ph-cursor-pointer {
  cursor: pointer;
}

.ph-cursor-help {
  cursor: help;
}

.ph-list-inside {
  list-style-position: inside;
}

.ph-list-outside {
  list-style-position: outside;
}

.ph-list-decimal {
  list-style-type: decimal;
}

.ph-flex-col {
  flex-direction: column;
}

.ph-content-center {
  align-content: center;
}

.ph-items-start {
  align-items: flex-start;
}

.ph-items-center {
  align-items: center;
}

.ph-items-baseline {
  align-items: baseline;
}

.ph-justify-center {
  justify-content: center;
}

.ph-justify-between {
  justify-content: space-between;
}

.ph-gap-y-3 {
  row-gap: 0.75rem;
}

.ph-gap-y-4 {
  row-gap: 1rem;
}

.ph-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.ph-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.ph-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.ph-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.ph-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.ph-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.ph-self-start {
  align-self: flex-start;
}

.ph-self-center {
  align-self: center;
}

.ph-overflow-hidden {
  overflow: hidden;
}

.ph-whitespace-nowrap {
  white-space: nowrap;
}

.ph-rounded-md {
  border-radius: 32px;
}

.ph-rounded-lg {
  border-radius: 0.5rem;
}

.ph-rounded-xl {
  border-radius: 0.75rem;
}

.ph-rounded-full {
  border-radius: 9999px;
}

.ph-rounded-xxs {
  border-radius: 16px;
}

.ph-rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ph-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.ph-rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.ph-rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.ph-border {
  border-width: 1px;
}

.ph-border-b-2 {
  border-bottom-width: 2px;
}

.ph-border-solid {
  border-style: solid;
}

.ph-border-none {
  border-style: none;
}

.ph-border-error-red {
  --tw-border-opacity: 1;
  border-color: rgba(171, 55, 61, var(--tw-border-opacity));
}

.ph-border-medium-gray {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
}

.ph-border-clover {
  --tw-border-opacity: 1;
  border-color: rgba(111, 178, 159, var(--tw-border-opacity));
}

.ph-border-mint {
  --tw-border-opacity: 1;
  border-color: rgba(193, 229, 219, var(--tw-border-opacity));
}

.ph-border-sage {
  --tw-border-opacity: 1;
  border-color: rgba(79, 127, 113, var(--tw-border-opacity));
}

.ph-border-transparent {
  border-color: transparent;
}

.hover\:ph-border-clover:hover {
  --tw-border-opacity: 1;
  border-color: rgba(111, 178, 159, var(--tw-border-opacity));
}

.ph-bg-dark-green {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 72, 73, var(--tw-bg-opacity));
}

.ph-bg-light-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
}

.ph-bg-primary-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.ph-bg-clover {
  --tw-bg-opacity: 1;
  background-color: rgba(111, 178, 159, var(--tw-bg-opacity));
}

.ph-bg-ivory {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 246, 242, var(--tw-bg-opacity));
}

.ph-bg-navajo {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 221, 175, var(--tw-bg-opacity));
}

.ph-bg-pianissimo {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 229, 224, var(--tw-bg-opacity));
}

.ph-bg-sage {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 127, 113, var(--tw-bg-opacity));
}

.ph-bg-transparent {
  background-color: transparent;
}

.ph-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.ph-bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.ph-bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.ph-bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.ph-bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.ph-stroke-dark-green {
  stroke: #284849;
}

.ph-stroke-white {
  stroke: #ffffff;
}

.ph-p-0 {
  padding: 0px;
}

.ph-p-4 {
  padding: 1rem;
}

.ph-p-0\.5 {
  padding: 0.125rem;
}

.ph-p-xs {
  padding: 20px;
}

.ph-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.ph-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.ph-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ph-px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.ph-px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.ph-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ph-px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.ph-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.ph-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.ph-px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.ph-px-sm {
  padding-left: 24px;
  padding-right: 24px;
}

.ph-py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.ph-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.ph-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ph-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.ph-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.ph-py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.ph-py-xs {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ph-pt-2 {
  padding-top: 0.5rem;
}

.ph-pt-4 {
  padding-top: 1rem;
}

.ph-pt-6 {
  padding-top: 1.5rem;
}

.ph-pt-12 {
  padding-top: 3rem;
}

.ph-pt-28 {
  padding-top: 7rem;
}

.ph-pt-sm {
  padding-top: 24px;
}

.ph-pr-0 {
  padding-right: 0px;
}

.ph-pr-6 {
  padding-right: 1.5rem;
}

.ph-pr-10 {
  padding-right: 2.5rem;
}

.ph-pb-2 {
  padding-bottom: 0.5rem;
}

.ph-pb-5 {
  padding-bottom: 5px;
}

.ph-pb-8 {
  padding-bottom: 2rem;
}

.ph-pb-sm {
  padding-bottom: 24px;
}

.ph-pl-0 {
  padding-left: 0px;
}

.ph-pl-4 {
  padding-left: 1rem;
}

.ph-text-left {
  text-align: left;
}

.ph-text-center {
  text-align: center;
}

.ph-text-right {
  text-align: right;
}

.ph-align-text-bottom {
  vertical-align: text-bottom;
}

.ph-font-sans {
  font-family: EuclidCircularB, sans-serif;
}

.ph-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.ph-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ph-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.ph-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.ph-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.ph-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.ph-text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.ph-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.ph-font-normal {
  font-weight: 400;
}

.ph-font-medium {
  font-weight: 500;
}

.ph-font-semibold {
  font-weight: 600;
}

.ph-font-bold {
  font-weight: 700;
}

.ph-uppercase {
  text-transform: uppercase;
}

.ph-capitalize {
  text-transform: capitalize;
}

.ph-normal-case {
  text-transform: none;
}

.ph-italic {
  font-style: italic;
}

.ph-leading-5 {
  line-height: 1.25rem;
}

.ph-leading-6 {
  line-height: 1.5rem;
}

.ph-leading-relaxed {
  line-height: 1.625;
}

.ph-tracking-wider {
  letter-spacing: 0.05em;
}

.ph-text-dark-green {
  --tw-text-opacity: 1;
  color: rgba(40, 72, 73, var(--tw-text-opacity));
}

.ph-text-error-red {
  --tw-text-opacity: 1;
  color: rgba(171, 55, 61, var(--tw-text-opacity));
}

.ph-text-clover {
  --tw-text-opacity: 1;
  color: rgba(111, 178, 159, var(--tw-text-opacity));
}

.ph-text-sage {
  --tw-text-opacity: 1;
  color: rgba(79, 127, 113, var(--tw-text-opacity));
}

.ph-text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.ph-underline {
  text-decoration: underline;
}

.ph-line-through {
  text-decoration: line-through;
}

.ph-no-underline {
  text-decoration: none;
}

.ph-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ph-opacity-0 {
  opacity: 0;
}

.ph-opacity-50 {
  opacity: 0.5;
}

.ph-opacity-75 {
  opacity: 0.75;
}

.ph-opacity-100 {
  opacity: 1;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.ph-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:ph-shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ph-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ph-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ph-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ph-ring-error-red {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(171, 55, 61, var(--tw-ring-opacity));
}

.ph-ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus\:ph-ring-error-red:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(171, 55, 61, var(--tw-ring-opacity));
}

.focus\:ph-ring-sage:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 127, 113, var(--tw-ring-opacity));
}

.ph-transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ph-transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ph-duration-30 {
  transition-duration: 30ms;
}

.ph-ease-0 {
  transition-timing-function: 0ms;
}

.ph-ease-40 {
  transition-timing-function: 40ms;
}

@media (min-width: 600px) {
  .sm\:ph-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:ph-mb-0 {
    margin-bottom: 0px;
  }

  .sm\:ph-inline-block {
    display: inline-block;
  }

  .sm\:ph-inline {
    display: inline;
  }

  .sm\:ph-flex {
    display: flex;
  }

  .sm\:ph-hidden {
    display: none;
  }

  .sm\:ph-w-60 {
    width: 15rem;
  }

  .sm\:ph-w-1\/2 {
    width: 50%;
  }

  .sm\:ph-w-full {
    width: 100%;
  }

  .sm\:ph-max-w-lg {
    max-width: 32rem;
  }

  .sm\:ph-max-w-4xl {
    max-width: 56rem;
  }

  .sm\:ph-flex-row {
    flex-direction: row;
  }

  .sm\:ph-flex-col {
    flex-direction: column;
  }

  .sm\:ph-flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:ph-items-stretch {
    align-items: stretch;
  }

  .sm\:ph-justify-start {
    justify-content: flex-start;
  }

  .sm\:ph-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:ph-pt-0 {
    padding-top: 0px;
  }

  .sm\:ph-pr-4 {
    padding-right: 1rem;
  }

  .sm\:ph-pl-4 {
    padding-left: 1rem;
  }

  .sm\:ph-text-left {
    text-align: left;
  }

  .sm\:ph-text-center {
    text-align: center;
  }

  .sm\:ph-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 700px) {
  .md\:ph-relative {
    position: relative;
  }

  .md\:ph-bottom-auto {
    bottom: auto;
  }

  .md\:ph--bottom-1 {
    bottom: -0.25rem;
  }

  .md\:ph--bottom-3 {
    bottom: -0.75rem;
  }

  .md\:ph--bottom-1\.5 {
    bottom: -0.375rem;
  }

  .md\:ph-mt-0 {
    margin-top: 0px;
  }

  .md\:ph-mt-5 {
    margin-top: 5px;
  }

  .md\:ph-mt-12 {
    margin-top: 3rem;
  }

  .md\:ph-mb-auto {
    margin-bottom: auto;
  }

  .md\:ph-block {
    display: block;
  }

  .md\:ph-flex {
    display: flex;
  }

  .md\:ph-hidden {
    display: none;
  }

  .md\:ph-h-3 {
    height: 0.75rem;
  }

  .md\:ph-h-6 {
    height: 1.5rem;
  }

  .md\:ph-w-3 {
    width: 0.75rem;
  }

  .md\:ph-w-6 {
    width: 1.5rem;
  }

  .md\:ph-w-auto {
    width: auto;
  }

  .md\:ph-w-2\/3 {
    width: 66.666667%;
  }

  .md\:ph-max-w-215 {
    max-width: 215px;
  }

  .md\:ph-max-w-lg {
    max-width: 32rem;
  }

  .md\:ph-max-w-2xl {
    max-width: 42rem;
  }

  .md\:ph-flex-45 {
    flex: 45%;
  }

  .md\:ph-flex-row {
    flex-direction: row;
  }

  .md\:ph-flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:ph-rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:ph-bg-transparent {
    background-color: transparent;
  }

  .md\:ph-px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .md\:ph-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:ph-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:ph-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:ph-px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:ph-py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .md\:ph-pt-8 {
    padding-top: 2rem;
  }

  .md\:ph-pl-10 {
    padding-left: 2.5rem;
  }

  .md\:ph-text-center {
    text-align: center;
  }

  .md\:ph-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:ph-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:ph-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:ph-text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 900px) {
  .lg\:ph-mt-0 {
    margin-top: 0px;
  }

  .lg\:ph-mt-7 {
    margin-top: 1.75rem;
  }

  .lg\:ph-flex-row {
    flex-direction: row;
  }

  .lg\:ph-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:ph-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:ph-pt-0 {
    padding-top: 0px;
  }
}

@media (min-width: 1100px) {
  .xl\:ph-w-1\/2 {
    width: 50%;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1440px) {
}

/* purgecss start ignore */

.loader-overlay_wrapper__2GxWd {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 70px);
  display: flex;
  background: linear-gradient(
    196.21deg,
    #f2e5e0 3.56%,
    #f6f2ee 43.43%,
    #eaecfa 101.58%
  );
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.loader-overlay_content__Hm4-K {
  max-width: 800px;
  text-align: center;
}

/* purgecss end ignore */

